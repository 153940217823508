import { camelCase, upperFirst } from 'lodash';
import { Optional } from 'typescript-optional';

import { arrayify } from '../../../utils/afsUtils';
import { toTitleCase } from '../utils';

export const getReportParams = <T>(
  approvalReport: reports.ReportInfoDetail,
): Optional<object> => {
  return Optional.ofNullable(approvalReport).map<object>((ar) => ar.params);
};

export const getReportParam = <T>(
  params: Optional<object>,
  key: string,
): Optional<T> => {
  return params.map((p: object) => p[key]);
};

export const getCountry = (approvalReport: reports.ReportInfoDetail) => {
  return getReportParam<string | string[]>(
    getReportParams(approvalReport),
    'countries',
  )
    .map<string[]>((o) => arrayify(o))
    .map((c) => c.map((i) => upperFirst(camelCase(i))).join(', '))
    .orElse('');
};

export const getAges = (approvalReport: reports.ReportInfoDetail) => {
  return getReportParam<
    {
      max: number;
      min: number;
      fraction: number;
    }[]
  >(getReportParams(approvalReport), 'age_ranges')
    .orElse([])
    .map(
      (range) =>
        range.min +
        ' - ' +
        range.max +
        ' (' +
        Math.floor(range.fraction * 100) +
        '%)',
    )
    .join(', ');
};

export const getSansRejector = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  return getReportParam<boolean>(
    getReportParams(approvalReport),
    'SansRejector',
  )
    .map<boolean>((sr) => sr === true)
    .map<string>((sr) => (sr ? 'SANS REJECTOR' : ''))
    .orElse('');
};

export const getExperienceRange = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  return getReportParam<number[] | number[][]>(
    getReportParams(approvalReport),
    'experience_range',
  )
    .map((i) => i.flat())
    .map<string>(
      (er) => Math.min(...er).toFixed(0) + ' - ' + Math.max(...er).toFixed(0),
    )
    .orElse('');
};

export const getMaleFrac = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  return getReportParam<number>(getReportParams(approvalReport), 'include_male')
    .map<number>((frac) => Math.floor(frac * 100))
    .map<string>((n) => n.toFixed(0))
    .orElse('0');
};

export const getFemaleFrac = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  return getReportParam<number>(
    getReportParams(approvalReport),
    'include_female',
  )
    .map<number>((frac) => Math.floor(frac * 100))
    .map<string>((n) => n.toFixed(0))
    .orElse('0');
};

export const getCategories = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  return getReportParam<string | string[]>(
    getReportParams(approvalReport),
    'competitive_set_folders',
  )
    .map<string[]>((cst) => arrayify(cst).map((i) => toTitleCase(i.trim())))
    .orElse([])
    .join(', ');
};

/**
 * Return the custom class db name from report params
 *
 * @param approvalReport target report info detail object
 * @returns custom class db name, if present
 */
export const getCustomClassDbName = (
  approvalReport: reports.ReportInfoDetail,
): Optional<string> => {
  return getReportParam<string>(
    getReportParams(approvalReport),
    'custom_class_db_name',
  );
};

/**
 * Get the formatted report category info string
 *
 * @param approvalReport target report info detail object
 * @returns formatted report category info string
 */
export const getReportCategoryInfo = (
  approvalReport: reports.ReportInfoDetail,
): string => {
  if (
    getCustomClassDbName(approvalReport).isPresent() &&
    getCustomClassDbName(approvalReport).get().length > 0
  ) {
    return `${getCategories(approvalReport)} (${getCustomClassDbName(approvalReport).get()})`;
  } else {
    return `${getCategories(approvalReport)}`;
  }
};
