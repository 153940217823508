import * as React from 'react';
import { useState } from 'react';
import { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from '@mui/material';
import CsvFileIcon from 'components/Icons/DownloadIcons/CsvFileIcon';
import PngFileIcon from 'components/Icons/DownloadIcons/PngFileIcon';
import SvgFileIcon from 'components/Icons/DownloadIcons/SvgFileIcon';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import { downloadSVG, downloadSVGAsPNG } from 'components/Report/utils';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import {
  CustomerPreferences,
  getLabelFont,
  showLabelFontPicker,
  showRotationSlider,
  useCustomerPreferences,
} from 'services/customerPreferences';

import DownloadMarketMapVectors from './DownloadMarketMapVectors';
import { contourLinesLayer } from './layers/ContourLinesLayer';
import { heatMapLayer } from './layers/HeatMapLayer';
import { labelsLayer } from './layers/LabelsLayer';
import { outerRingLayer } from './layers/OuterRingLayer';
import { productsLayer } from './layers/ProductsLayer';
import { vectorsLayer } from './layers/VectorsLayer';
import {
  MapOptions,
  ZOOM_MAX,
  ZOOM_MIN,
  applyZoomEvent,
  setContoursVisible,
  setHeatmapVisible,
  setLabelFactor,
  setProductsVisible,
  setRotationAngle,
  setVectorsVisible,
  setGGVarsVisible,
  setZoom,
} from '../MapOptions';

function renderLayers(
  history: History,
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  mapOptions: MapOptions,
  width: number,
  height: number,
  isProd: boolean,
  productRMax: number,
  heatmapArray: marketmap.HeatMapData[],
  allowPixelRequest: boolean,
  contourArray: marketmap.ContourMapData[],
  productVectorArray: marketmap.ProductVectorData[],
  ggVarArray: marketmap.LabelData[],
  refFlavorArray: marketmap.LabelData[],
  excludedProducts: ProductVersionSet,
  projectId: number,
  reportId: string,
  customerPreferences: CustomerPreferences,
  workspaceId: number,
  userId: number,
  labelFont: string,
  contourThreshold: number,
  contourBandwidth: number,
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>,
  isSuperAdmin?: boolean,
  disableRefFlavorControl?: boolean,
) {
  if (mapOptions.heatmapVisible) {
    heatMapLayer(
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.heatmapTransparency,
      mapOptions.tx,
      mapOptions.ty,
      productRMax,
      heatmapArray,
      allowPixelRequest,
      customerPreferences,
      workspaceId,
      userId,
      projectId,
      reportId,
      isSuperAdmin,
    );
  }
  if (mapOptions.contoursVisible) {
    contourLinesLayer(
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.contoursTransparency,
      mapOptions.tx,
      mapOptions.ty,
      width,
      height,
      productRMax,
      contourArray,
      contourThreshold,
      contourBandwidth,
      customerPreferences,
    );
  }
  outerRingLayer(container, r, mapOptions, customerPreferences);
  if (mapOptions.vectorsVisible) {
    labelsLayer(
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.tx,
      mapOptions.ty,
      width,
      height,
      isProd,
      refFlavorArray,
      (ggVarArray = mapOptions.ggVarsVisible ? ggVarArray : []),
      labelFont,
      customerPreferences,
    );
  }
  if (mapOptions.ggVarsVisible) {
    if (disableRefFlavorControl)
      labelsLayer(
        container,
        r,
        mapOptions.zoom,
        mapOptions.rotationAngle,
        mapOptions.tx,
        mapOptions.ty,
        width,
        height,
        isProd,
        refFlavorArray,
        (ggVarArray = mapOptions.ggVarsVisible ? ggVarArray : []),
        labelFont,
        customerPreferences,
      );
    else
      labelsLayer(
        container,
        r,
        mapOptions.zoom,
        mapOptions.rotationAngle,
        mapOptions.tx,
        mapOptions.ty,
        width,
        height,
        isProd,
        (refFlavorArray = mapOptions.vectorsVisible ? refFlavorArray : []),
        ggVarArray,
        labelFont,
        customerPreferences,
      );
  }
  if (mapOptions.vectorsVisible) {
    vectorsLayer(
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.tx,
      mapOptions.ty,
      ggVarArray,
      customerPreferences,
      mapOptions.vectorsTransparency,
    );
  }
  if (mapOptions.ggVarsVisible) {
    vectorsLayer(
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.tx,
      mapOptions.ty,
      ggVarArray,
      customerPreferences,
      mapOptions.vectorsTransparency,
    );
  }
  if (mapOptions.productsVisible) {
    productsLayer(
      history,
      container,
      r,
      mapOptions.zoom,
      mapOptions.rotationAngle,
      mapOptions.tx,
      mapOptions.ty,
      mapOptions.labelFactor,
      labelFont,
      productRMax,
      productVectorArray,
      heatmapArray,
      excludedProducts,
      customerPreferences,
      projectId,
      reportId,
      setExcludedProducts,
    );
  }
}

function downloadCSV(
  event: React.MouseEvent,
  ref: React.MutableRefObject<any>,
) {
  ref.current.link.click();
}

interface MarketMapProps {
  isProd: boolean;
  productRMax: number;
  productVectorArray: marketmap.ProductVectorData[];
  ggVarArray: marketmap.LabelData[];
  refFlavorArray: marketmap.LabelData[];
  heatmapArray: marketmap.HeatMapData[];
  contourArray: marketmap.ContourMapData[];
  excludedProducts: ProductVersionSet;
  projectId?: number;
  reportId: string;
  allowPixelRequest: boolean;
  granularity: number;
  contourThreshold: number;
  contourBandwidth: number;
  viewerUserId?: number;
  workspaceId?: number;
  userId?: number;
  mapOptions: MapOptions;
  setMapOptions: (newMapOptions: MapOptions) => void;
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>;
  isSuperAdmin?: boolean;
  disableRefFlavorControl?: boolean;
}

const MarketMap: React.FC<MarketMapProps> = (props) => {
  const {
    isProd,
    productRMax,
    productVectorArray,
    ggVarArray,
    refFlavorArray,
    heatmapArray,
    contourArray,
    excludedProducts,
    projectId,
    reportId,
    viewerUserId,
    workspaceId,
    userId,
    allowPixelRequest,
    granularity,
    contourThreshold,
    contourBandwidth,
    mapOptions,
    setMapOptions,
    setExcludedProducts,
    isSuperAdmin,
    disableRefFlavorControl,
  } = props;

  const history = useHistory();
  const customerPreferences = useCustomerPreferences();

  const [labelFont, setLabelFont] = useState(getLabelFont(customerPreferences));

  // Dimensions (Natural Market Map SVG size)
  const primaryDimension = granularity;

  const width = primaryDimension;
  const height = 0.8 * primaryDimension;

  // Inspired by https://ncoughlin.com/posts/d3-react/

  // Element References
  const svgRef = useRef(null);
  const csvLink = useRef();

  useEffect(() => {
    // D3 Code

    // Market Map Radius
    const r = height * 0.35;

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed('market-map-svg', true)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${width} ${height}`);

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    svg
      .append('style')
      .attr('type', 'text/css')
      .text(
        "@import url('https://fonts.googleapis.com/css?family=Montserrat:400,300,600,700,800');",
      );

    const container = svg
      .append('g')
      .classed('container', true)
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const changeZoom = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([ZOOM_MIN, ZOOM_MAX])
      .filter((event: any) => {
        return event.type === 'wheel' ? event.metaKey : true;
      })
      .on('zoom', (event: d3.D3ZoomEvent<HTMLCanvasElement, any>) => {
        setMapOptions(applyZoomEvent(customerPreferences, mapOptions, event));
      });

    svg.call(changeZoom);

    // Remove any tooltips as not visible after any redraw
    d3.selectAll('.tooltip').style('opacity', 0);

    renderLayers(
      history,
      container,
      r,
      mapOptions,
      width,
      height,
      isProd,
      productRMax,
      heatmapArray,
      allowPixelRequest,
      contourArray,
      productVectorArray,
      ggVarArray,
      refFlavorArray,
      excludedProducts,
      projectId,
      reportId,
      customerPreferences,
      workspaceId,
      userId,
      labelFont,
      contourThreshold,
      contourBandwidth,
      setExcludedProducts,
      isSuperAdmin,
    );
  }, [
    customerPreferences,
    productVectorArray,
    ggVarArray,
    refFlavorArray,
    heatmapArray,
    excludedProducts,
    labelFont,
    mapOptions,
  ]); // redraw chart if data changes

  const divStyle: React.CSSProperties = {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
    overflow: 'hidden',
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div id="market-map" className="market-map" style={divStyle}>
          <svg
            id="market-map-svg"
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
          />
        </div>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={4}>
          <InputLabel id="market-map-zoom-label" shrink>
            Zoom
          </InputLabel>
          <Slider
            value={mapOptions.zoom}
            getAriaValueText={() => 'hi'}
            onChange={(event, newValue: number) =>
              setMapOptions(setZoom(mapOptions, newValue))
            }
            aria-labelledby="market-map-zoom-label"
            valueLabelDisplay="auto"
            step={0.05}
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            size="small"
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <InputLabel id="market-map-label-size-label" shrink>
            Label Size
          </InputLabel>
          <Slider
            value={mapOptions.labelFactor}
            getAriaValueText={() => 'hi'}
            onChange={(event, newValue: number) =>
              setMapOptions(setLabelFactor(mapOptions, newValue))
            }
            aria-labelledby="market-map-label-size-label"
            valueLabelDisplay="auto"
            step={0.05}
            min={0.5}
            max={2}
            size="small"
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <IconButton
            id="svg-link"
            size="small"
            onClick={(e) => downloadSVG(e, 'market-map-svg')}
          >
            <SvgFileIcon fontSize="large" />
          </IconButton>
          <IconButton
            id="png-link"
            size="small"
            onClick={(e) =>
              downloadSVGAsPNG(e, 'market-map-svg', width, height)
            }
          >
            <PngFileIcon fontSize="large" />
          </IconButton>
          <IconButton
            id="csv-link"
            size="small"
            onClick={(e) => downloadCSV(e, csvLink)}
          >
            <CsvFileIcon fontSize="large" />
            <DownloadMarketMapVectors
              ggvarArray={ggVarArray}
              csvLink={csvLink}
            />
          </IconButton>
        </Grid>
      </Grid>
      {showRotationSlider(customerPreferences) && (
        <Grid item xs={12}>
          <InputLabel id="market-map-rotatee-label" shrink>
            Rotate
          </InputLabel>
          <Slider
            value={Math.round((mapOptions.rotationAngle * 180) / Math.PI)}
            getAriaValueText={() => 'hi'}
            onChange={(event, newValue: number) =>
              setMapOptions(
                setRotationAngle(mapOptions, (newValue / 180) * Math.PI),
              )
            }
            aria-labelledby="market-map-rotatee-label"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={360}
            size="small"
          />
        </Grid>
      )}
      {showLabelFontPicker(customerPreferences) && (
        <Grid item xs={12} container>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="label-font-picker" shrink>
                Label Font
              </InputLabel>
              <Select
                variant="standard"
                id="demo-simple-select"
                fullWidth
                value={labelFont}
                label="Label Font"
                aria-label="auto"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLabelFont(e.target.value)
                }
              >
                <MenuItem value={'Arial'}>Arial</MenuItem>
                <MenuItem value={'Montserrat'}>Montserrat</MenuItem>
                <MenuItem value={'Times New Roman'}>Times New Roman</MenuItem>
                <MenuItem value={'monospace'}>Monospace</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
      <Grid item xs={12} container style={{ paddingTop: 10 }}>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapOptions.contoursVisible}
                onChange={() =>
                  setMapOptions(
                    setContoursVisible(mapOptions, !mapOptions.contoursVisible),
                  )
                }
                name="contours"
                color="primary"
              />
            }
            label="Contours"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapOptions.ggVarsVisible}
                onChange={() =>
                  setMapOptions(
                    disableRefFlavorControl
                      ? setVectorsVisible(
                          mapOptions,
                          !mapOptions.vectorsVisible,
                        )
                      : setGGVarsVisible(mapOptions, !mapOptions.ggVarsVisible),
                  )
                }
                name="ggVarsSignatures"
                color="primary"
              />
            }
            label="GGVar Signatures"
          />
        </Grid>
        {!disableRefFlavorControl && (
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mapOptions.vectorsVisible}
                  onChange={() =>
                    setMapOptions(
                      setVectorsVisible(mapOptions, !mapOptions.vectorsVisible),
                    )
                  }
                  name="flavorSignatures"
                  color="primary"
                />
              }
              label="Flavor Signatures"
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapOptions.productsVisible}
                onChange={() =>
                  setMapOptions(
                    setProductsVisible(mapOptions, !mapOptions.productsVisible),
                  )
                }
                name="products"
                color="primary"
              />
            }
            label="Products"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapOptions.heatmapVisible}
                onChange={() =>
                  setMapOptions(
                    setHeatmapVisible(mapOptions, !mapOptions.heatmapVisible),
                  )
                }
                name="heatmap"
                color="primary"
              />
            }
            label="Heatmap"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MarketMap;
