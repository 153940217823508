import { gql } from '@apollo/client';

export const FIND_REPORT_ID_QUERY = gql`
  query allReportIDs($reportID: UUID) {
    allReportJobs(
      orderBy: [STARTED_AT_DESC]
      condition: { reportId: $reportID, reportStatus: SUCCESS }
    ) {
      nodes {
        reportId
        projectName
        clientName
      }
    }
  }
`;
