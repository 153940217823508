import { reduxForm } from 'redux-form';

import { AgeRange } from './Demographics/Demographics';
import RequestReport from './RequestReport';
import formSubmit from '../../actions/formSubmit';
import { REQUEST_REPORT_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);
const validateNotNull = (val) => !(val == null);
const validateBool = (val) => !(val === true || val === false);
const validateAgeWeights = (age_ranges: AgeRange[]) =>
  1 === age_ranges.reduce((acc, val) => acc + val.fraction, 0);

const constants = {
  // not altered by the form
  texture_weight_threshold_list: 2,
  refresh_texture: false,
  n_cluster_archetype: 2,
  n_cluster_archetype_max: 6,
  n_review_archetype: 2,
  n_review_archetype_max: 5,
  arch_age_start: [20, 30, 40, 50, 60],
  min_unique_reviewers: 20,
  include_market_maps: true,
  include_pairwise_comparison: true,
  include_gradients: true,
  apply_margEffect_ggvar_range: true,
  maxRowSummaryTable: 30,
  maxRowDecomSubTable: 20,
  minReviews: 5,
  alcoholLegal: false,
  apply_doofus_factor: true,
  new_reference_flavors_allowed: false,
  is_opt_test_mode: false,
  hot_patch_post_label_prop_data: false,
  parallelCode: true,
  pull_glp_from_s3: true,
  glp_s3_key: 'data/global_label_prop.csv',
  glp_s3_bucket: 'gastrograph-argo-artifacts',
  constraint_levels: ['HC', 'MC', 'LC'],
  post_label_prop_data_path: 'N/A',
  supplement_competitive_set_products: false,
};

export default reduxForm({
  form: REQUEST_REPORT_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(REQUEST_REPORT_FORM));
  },
  validate: (values) => {
    const validated = {
      job_definition: validateNotNull(values.job_definition),
      report_type: validation(values.report_type),
      project_name: validation(values.project_name),
      demographic: validation(values.demographic),
      client_name: validation(values.client_name),
      countries: validation(values.countries),
      clts: validation(values.clts),
      // raceEthnicity: validation(values.raceEthnicity), // For Single country
      age_ranges: validateAgeWeights(values.age_ranges),
      // smokingHabits: validation(values.smokingHabits),
      // socioEcon: validation(values.socioEcon),
    };

    return validated;
  },
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues: {
    ...constants,
    job_definition: null,
    competitive_set_rich: [],
    custom_class_db_rich: [],
    custom_class_db_name: '',
    client_allowed_workspaces: [],
    competitive_set_folders: [],
    report_type: '',
    folderProducts: {},
    countries: {},
    clts: [],
    ageCategory: 'Adult',
    age_ranges: [{ min: 8, max: 50, fraction: 1 }],
    age_range_weight_0: 100,
    experience_range: [[1, 2, 3]],
    client_name: '',
    project_name: '',
    workspace_id: null,
    simulate_pq_values: false,
    n_sims: [150, 200],
    include_archetypes: false,
    include_texture: true,
    texture_only: false,
    texture_data_only: false,
    combine_artificial_sweeteners: false,
    showConfidence: true,
    show_TC_se: true,
    generate_ppt: false,
    profilingEnabled: false,
    override_invalid: false,
    show_pg_gNorm: false,
    plotNorm: false,
    extrema_analysis: true,
    includeNoveltyScores: false,
    include_female: 0.5,
    asian: true,
    black: true,
    hispanic: true,
    white: true,
    usa_region: null,
    SansRejector: false,
    smoking_habits: null,
    only_include_parents: false,
    products_of_special_interest: [],
    timestamp_start: null,
    experimental_features: true,
    report_id_to_inherit_parent_data_sources: null,
    optimization: {
      project_name: null,
      workspace_id: null,
      parent_job_id: null,
      products_to_opt: null,
      ggvars_to_lock: [],
      ggvar_constraints: [],
      ref_flavor_to_lock: [],
      ref_flavor_to_remove: [],
      ref_flavor_to_tune: [],
      rangeReffRange: [],
      NRF: true,
      NNRF: true,
      NC_constraint: false,
      ULC_constraint: false,
      VLC_constraint: false,
      LC_constraint: true,
      MC_constraint: true,
      HC_constraint: true,
      VHC_constraint: false,
      UHC_constraint: false,
      enable_norms: false,
      tuning_plots: true,
      optimize_flavor: true,
      optimize_texture: false,
      plot_norm: false,
      artifact_path: null,
      selected_report: null,
    },
  },
})(RequestReport);
