import { CountryMetadata } from 'constants/country';

import * as React from 'react';
import { useState } from 'react';

import { useTheme } from '@emotion/react';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PaddedSlider from 'components/PaddedSlider';
import { RegionListOptions } from 'hooks/useRegionsList';
import { concat, range } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import AgeSlider from './AgeSlider';
import FieldCheckBox from '../../../components/FieldCheckBox';
import FieldTextInput from '../../../components/FieldTextInput';
import FormInput from '../../../components/FormInput';
import FormInputRadio from '../../../components/FormInputRadio';
import FormInputSelect from '../../../components/FormInputSelect';
import Tag from '../../../components/FormInputTag/Tag';
import MaterialButton from '../../../components/MaterialButton';
import { PageHeader, PageTitle } from '../../../styles/themeComponents';
import { arrayify } from '../../../utils/afsUtils';
import styles from '../RequestReport.module.css';
import useStyles from '../useStyles';

const validateAgeRangeWeight = (val, allVals): string => {
  const ageRangeWeights = Object.keys(allVals).filter((allVal) =>
    allVal.startsWith('age_range_weight'),
  );
  let rangeWeightSum = 0;
  for (let i = 0; i < ageRangeWeights.length; i++) {
    if (isNaN(allVals[`age_range_weight_${i}`])) {
      return 'Not a number';
    }
    rangeWeightSum += Number(allVals[`age_range_weight_${i}`]);
  }
  return rangeWeightSum != 100 ? 'not 100' : undefined;
};

const required = (value) =>
  Object.keys(value ?? {}).length !== 0 ? undefined : 'Required';

const closeSlider = (
  change: (field: string, value: any) => void,
  age_ranges: AgeRange[],
  idx: number,
) => {
  change(
    'age_ranges',
    concat(age_ranges.slice(0, idx), age_ranges.slice(idx + 1)),
  );
};

const addSlider = (
  change: (field: string, value: any) => void,
  age_ranges: AgeRange[],
  ageCategory: 'Adult' | 'Children',
  lastIdx: number,
) => {
  switch (ageCategory) {
    case 'Adult':
      change(
        'age_ranges',
        concat(age_ranges, [
          { min: 20, max: 50, fraction: remainingWeight(age_ranges) },
        ]),
      );
      break;
    case 'Children':
      change(
        'age_ranges',
        concat(age_ranges, [
          { min: 8, max: 16, fraction: remainingWeight(age_ranges) },
        ]),
      );
      break;
  }
  change(
    `age_range_weight_${lastIdx + 1}`,
    Math.round(remainingWeight(age_ranges) * 100),
  );
};

const remainingWeight = (age_ranges: AgeRange[]) =>
  1 - age_ranges.reduce((sum, age_range) => sum + age_range.fraction, 0);

export interface AgeRange {
  min: number;
  max: number;
  fraction: number;
}
export interface DemographicsProps {
  displayMode: boolean;
  allCountries: Set<CountryMetadata>;
  allRegions: RegionListOptions[];
  countries: SelectOption;
  clts: string[];
  age_ranges: AgeRange[];
  ageCategory: 'Adult' | 'Children';
  include_female: number;
  change: (field: string, value: any) => void;
}

const Demographics: React.FC<DemographicsProps> = (props) => {
  const {
    displayMode,
    allCountries,
    allRegions,
    countries,
    clts,
    age_ranges,
    ageCategory,
    include_female,
    change,
  } = props;

  const { t } = useTranslation();

  const classes = useStyles(useTheme());

  const [showDemographics, setShowDemographics] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    countries?.value,
  );
  const [filteredRegions, setFilteredRegions] = useState<RegionListOptions[]>(
    [],
  );

  const updateDemographics = (value: boolean, country: string) => {
    setShowDemographics(value);
    setSelectedCountry(country);
    setFilteredRegions(allRegions.filter((r) => r.country === country));
  };

  return (
    <div>
      {!displayMode && (
        <div>
          <PageHeader>{t('navigation.reports')}</PageHeader>
          <PageTitle>
            {`${t('reports.createReportRequest')}: ${t(
              'reports.demographics',
            )}`}
          </PageTitle>
        </div>
      )}

      <div className={styles.competitiveSetTable}>
        <table>
          <tbody>
            {/* Countries element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  {t('reports.countries')}
                  {' *'}
                </span>
              </td>
              <td className={styles.valueColumn}>
                {displayMode ? (
                  <div className={styles.tag}>
                    {countries && (
                      // For Multi country
                      // countries.map(country => (
                      //   <div key={country.label}>
                      //     <Tag readOnly={true} label={country.label} />
                      //   </div>
                      // ))}
                      <div key={countries.label}>
                        <Tag readOnly={true} label={countries.label} />
                      </div>
                    )}
                  </div>
                ) : (
                  <Field
                    name="countries"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    key="countries"
                    options={Array.from(allCountries).map((country) => ({
                      label: `${country.emoji} ${country.name}`,
                      value: country.dbname,
                    }))}
                    isSearchable
                    isClearable
                    placeholder={t('reports.countryPlaceholder')}
                    required
                    validate={required}
                    // checkbox
                    // isMulti
                    onChange={(_, newValue) => {
                      change('clts', []);
                      updateDemographics(
                        newValue?.value == 'usa',
                        newValue?.value,
                      );
                    }}
                  />
                )}
              </td>
            </tr>

            {/* Regions element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  {t('reports.regions')}
                </span>
              </td>
              <td className={styles.valueColumn}>
                {displayMode ? (
                  <div className={styles.tag}>
                    {arrayify(clts) &&
                      arrayify(clts).map((region) => (
                        <div key={region}>
                          <Tag readOnly={true} label={region} />
                        </div>
                      ))}
                  </div>
                ) : (
                  allRegions
                    .filter((r) => r.country === selectedCountry)
                    .map((region) => {
                      return (
                        <Field
                          name={'regions.' + region.slug}
                          component={FieldCheckBox}
                          key={'regions.' + region.slug}
                          label={region.label}
                          val={arrayify(clts).includes(region.value)}
                          value={region.value}
                          onChange={(event) => {
                            if (
                              event.target.checked &&
                              !arrayify(clts).includes(region.value)
                            ) {
                              const newClts = [...arrayify(clts), region.value];
                              change('clts', newClts);
                            } else {
                              const newClts = arrayify(clts).filter(
                                (r) => r !== region.value,
                              );
                              change('clts', newClts);
                            }
                          }}
                          checkboxContainer
                        />
                      );
                    })
                )}
              </td>
            </tr>

            {/* Precent Female element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>Percent Female</span>
              </td>
              <td className={styles.valueColumn}>
                {displayMode ? (
                  <div className={styles.tag}>
                    {'Percent Female: ' + include_female}
                  </div>
                ) : (
                  <PaddedSlider
                    key={'genderslider'}
                    step={1}
                    min={0}
                    max={100}
                    marks={range(0, 100, 5).map((d) => ({
                      value: d,
                      label: d,
                    }))}
                    valueLabelDisplay="on"
                    value={Math.round(100 * include_female)}
                    onChange={(_event, newValue) =>
                      change('include_female', (newValue as number) / 100)
                    }
                    size="small"
                    classes={{
                      root: classes.root,
                      valueLabel: classes.valueLabel,
                      markLabel: classes.markLabel,
                      mark: classes.mark,
                      thumb: classes.thumb,
                    }}
                  />
                )}
              </td>
            </tr>

            {/* Demographics (USA Only) element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  Demographics (USA only)
                </span>
              </td>
              <td className={styles.valueColumn}>
                {['asian', 'black', 'hispanic', 'white'].map((d) => (
                  <Field
                    key={d}
                    name={d}
                    label={d}
                    component={FieldCheckBox}
                    disabled={!showDemographics}
                  />
                ))}
              </td>
            </tr>

            {/* USE Region element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  USA Region (USA only)
                </span>
              </td>
              <td className={styles.valueColumn}>
                <Field
                  name="usa_region"
                  component={FormInputRadio}
                  key="usa_region"
                  options={['East Coast', 'West Coast'].map((d) => ({
                    label: d,
                    value: d,
                  }))}
                  disabled={!showDemographics}
                />
              </td>
            </tr>

            {/* Ages element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>{t('reports.ages')}</span>
              </td>
              <td className={styles.valueColumn}>
                {ageCategory &&
                  age_ranges.map((age_range: AgeRange, i: number) => (
                    <div className={styles.ageSliderContainer} key={i}>
                      <AgeSlider
                        ageCategory={ageCategory}
                        value={[age_range.min, age_range.max]}
                        disabled={displayMode}
                        onChange={(newValue) =>
                          change(
                            'age_ranges',
                            age_ranges.map((age_index, index) =>
                              index === i
                                ? {
                                    ...age_index,
                                    min: newValue[0],
                                    max: newValue[1],
                                  }
                                : age_index,
                            ),
                          )
                        }
                      />
                      <Field
                        name={`age_range_weight_${i}`}
                        disabled={displayMode}
                        component={FieldTextInput}
                        label="Range Weight"
                        onChange={(newValue) =>
                          change(
                            'age_ranges',
                            age_ranges.map((age_index, index) =>
                              index === i
                                ? {
                                    ...age_index,
                                    fraction:
                                      Math.round(
                                        parseFloat(newValue?.target?.value),
                                      ) / 100 ?? 0,
                                  }
                                : age_index,
                            ),
                          )
                        }
                        required
                        validate={validateAgeRangeWeight}
                      />
                      {age_ranges.length > 1 && !displayMode && (
                        <IconButton
                          onClick={() => closeSlider(change, age_ranges, i)}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      )}

                      {!displayMode &&
                        remainingWeight(age_ranges) !== 0 &&
                        i === age_ranges.length - 1 && (
                          <MaterialButton
                            size="small"
                            onClick={() =>
                              addSlider(change, age_ranges, ageCategory, i)
                            }
                          >
                            <AddIcon />
                            {t('reports.addAgeRange')}
                          </MaterialButton>
                        )}
                    </div>
                  ))}
              </td>
            </tr>

            {/* Smoking Habits element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  {t('reports.smokingHabits')}
                </span>
              </td>
              <td className={styles.valueColumn}>
                <Field
                  name="smoking_habits"
                  component={FormInputRadio}
                  key="smoking_habits"
                  options={t('smokingHabitsOptions', { returnObjects: true })}
                  required
                  disabled={displayMode}
                />
              </td>
            </tr>

            {/* Sans Rejector element */}
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  {t('reports.sansRejector')}
                </span>
              </td>
              <td className={styles.valueColumn}>
                <Field
                  name="SansRejector"
                  component={FieldCheckBox}
                  disabled={displayMode}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Demographics;
