import * as React from 'react';

import { Divider, Grid } from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import submitConchBatch from 'consumers/httpSubmitBatch';
import useGgEnvironment from 'hooks/useGgEnvironment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { jobDefinition } from 'utils/conchUtils';
import { v4 as uuidv4 } from 'uuid';

import flavorNoTextureOpt from './opts/flavorNoTextureOpt.json';
import iceCreamOptTestParams from './opts/iceCreamTest.json';
import noTuningOptTestParams from './opts/noTuningTest.json';
import optTortureTestParams from './opts/optTortureTest.json';
import textureOptTestParams from './opts/textureOptTest.json';
import archetypesTestParms from './params/archetypesTest.json';
import cocktailTestParams from './params/cocktailTest.json';
import coffeeTestParams from './params/coffeeTest.json';
import computationalCreativityParams from './params/computationalCreativity.json';
import dataInheritance from './params/dataInheritance.json';
import iceCreamTestParams from './params/iceCreamTest.json';
import lemonLimeTestParams from './params/lemonLimeTest.json';
import methodThreeTestParams from './params/methodThreeTest.json';
import rejector1TestParams from './params/rejector1.json';
import rejector2TestParams from './params/rejector2.json';
import sodaTestParams from './params/sodaTest.json';
import textureOnlyTestParams from './params/textureOnlyTest.json';
import tortureTestParams from './params/tortureTest.json';
import appToastAdd from '../../actions/appToastAdd';

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};

const formatDate = (date: Date): string => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
};

const addRuntimeParams = <T extends conch.ReportParams>(
  params: T,
  jobdef: conch.JobDefinition,
  projectName: string,
): T => {
  return {
    ...params,
    reportUID: uuidv4(),
    project_name: projectName,
    job_definition: jobdef,
  };
};

const submitSmokeTest = async (
  test: conch.TestDefinition,
  environment: SensoryLinkEnvironment,
  dispatch: Dispatch<any>,
) => {
  const projectName = environment + '-test-' + test.testName;
  const jobname = 'SmokeTest_' + projectName + '-' + formatDate(new Date());
  const jobdef = jobDefinition(environment);
  const runtimeParams = addRuntimeParams(test.params, jobdef, projectName);

  const response = await submitConchBatch(
    jobname,
    jobdef,
    test.boxSize,
    runtimeParams,
  );

  if (response.ok) {
    dispatch(
      appToastAdd({
        durationMilliseconds: 4000,
        message: `Smoke Test ${projectName} submitted`,
        title: 'Smoke Test Submitted',
        toastKey: `toast_${Date.now()}`,
      }),
    );
  } else {
    dispatch(
      appToastAdd({
        durationMilliseconds: 4000,
        message: await response.text(),
        title: 'Smoke Test Failed!',
        toastKey: `toast_${Date.now()}`,
      }),
    );
  }
};

const submitAllSmokeTests = (
  tests: conch.TestDefinition[],
  environment: SensoryLinkEnvironment,
  dispatch: Dispatch<any>,
) => {
  tests.forEach((test) => submitSmokeTest(test, environment, dispatch));
};

const SmokeTests: React.FC = () => {
  const { t } = useTranslation();
  const environment = useGgEnvironment();
  const dispatch = useDispatch();

  if (!environment) {
    return <div></div>;
  }

  const smokeTests: conch.TestDefinition[] = [
    {
      displayName: 'Ice Cream Test',
      testName: 'iceCreamTest',
      params: iceCreamTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Coffee Test',
      testName: 'coffeeTest',
      params: coffeeTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Archetypes Test',
      testName: 'archetypesTest',
      params: archetypesTestParms as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Cocktail Test',
      testName: 'cocktailTest',
      params: cocktailTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Data Inheritance',
      testName: 'dataInheritance',
      params: dataInheritance as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Lemon Lime Test',
      testName: 'lemonLimeTest',
      params: lemonLimeTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-8xlarge',
    },
    {
      displayName: 'Method Three Test',
      testName: 'methodThreeTest',
      params: methodThreeTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Market Survey Torture Test',
      testName: 'tortureTest',
      params: tortureTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Rejector 1 Test',
      testName: 'rejector1Test',
      params: rejector1TestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Rejector 2 Test',
      testName: 'rejector2Test',
      params: rejector2TestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Soda Test',
      testName: 'sodaTest',
      params: sodaTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Texture Only Test',
      testName: 'textureOnlyTest',
      params: textureOnlyTestParams as conch.MarketSurveyReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Ice Cream Opt Test',
      testName: 'iceCreamOptTest',
      params: iceCreamOptTestParams as conch.OptimizationReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'No Tuning Opt Test',
      testName: 'noTuningOptTest',
      params: noTuningOptTestParams as conch.OptimizationReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Flavor Opt No Texture',
      testName: 'noTuningOptTest',
      params: flavorNoTextureOpt as conch.OptimizationReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Texture Opt Test',
      testName: 'textureOptTest',
      params: textureOptTestParams as conch.OptimizationReportParams,
      boxSize: 'gg-queue-efs-2xlarge',
    },
    {
      displayName: 'Opt Torture Test',
      testName: 'optTorutreTest',
      params: optTortureTestParams as conch.OptimizationReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
    {
      displayName: 'Computational Creativity Test',
      testName: 'computationalCreativityTest',
      params:
        computationalCreativityParams as conch.ComputationalCreativityReportParams,
      boxSize: 'gg-queue-efs-4xlarge',
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialButton
          variant="outlined"
          onClick={() => submitAllSmokeTests(smokeTests, environment, dispatch)}
          soft
          teal
        >
          {t('admin.allSmokeTests')}
        </MaterialButton>
      </Grid>
      <Grid item xs={12} style={{ padding: 10 }}>
        <Divider />
      </Grid>
      {smokeTests.map((test) => (
        <Grid item key={test.testName} xs={3}>
          <MaterialButton
            variant="outlined"
            onClick={() => submitSmokeTest(test, environment, dispatch)}
            soft
            teal
          >
            {test.displayName}
          </MaterialButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default SmokeTests;
