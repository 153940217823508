import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ReportTextureClustersQuery from '@graphql/queries/ReportTextureClusters';
import { ChangeHistory, RadioButtonUnchecked } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Divider,
  FormControlLabel,
  InputLabel,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import { Grid } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { MapOptions } from 'components/Report/Maps/MapOptions';
import ReportTextureMap from 'components/Report/Maps/ReportMarketMap/ReportTextureMap';
import ReportTextureTernary from 'components/Report/Maps/ReportTextureTernary/ReportTextureTernary';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import ReportTextureCluster from 'components/Report/Texture/ReportTextureCluster';
import {
  computeDefaultMinConfidence,
  mapAndRegroupClusterTexturesMinConfidence,
} from 'components/Report/Texture/ReportTextureCluster/textureClusterUtils';
import ReportTextureComposition from 'components/Report/Texture/ReportTextureComposition';
import ReportTexturePreference from 'components/Report/Texture/ReportTexturePreference';
import useReportSummary from 'hooks/useReportSummary';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectViewerUserId from 'selectors/viewerUserId';

import ReportTexturePreferenceQuery from '../../../graphql/queries/ReportTexturePreferenceQuery';
import selectWorkspaceProducerId from '../../../selectors/workspaceProducerId';
import styles from '../ProjectSummary.module.css';
import { showOriginalProductsToggle } from '../utils';

interface Props {
  projectId: number;
  reportId: string;
  excludedProducts: ProductVersionSet;
  textureMapOptions: MapOptions;
  setTextureMapOptions: (newMapOptions: MapOptions) => void;
  parentReportId?: string;
  displayParentReport?: boolean;
  setDisplayParentReport?: (b: boolean) => void;
}

const ProjectTextureSummary: React.FC<Props> = (props) => {
  const {
    projectId,
    reportId,
    excludedProducts = new ProductVersionSet(),
    textureMapOptions,
    setTextureMapOptions,
    parentReportId,
    displayParentReport,
    setDisplayParentReport,
  } = props;
  const { t } = useTranslation();
  const [textureConfidence, setTextureConfidence] = useState<number>(100);

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const [showTernaryPlots, setShowTernaryPlots] =
    React.useState<boolean>(false);

  const [useTextureClusterNames, setUseTextureClusterNames] =
    React.useState<boolean>(true);

  const {
    loading,
    error,
    data: reportSummary,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: reportTexturePreferenceLoading,
    error: reportTexturePreferenceError,
    data: reportTexturePreferences,
  } = useQuery<reports.ReportTexturePreferencesResponse>(
    ReportTexturePreferenceQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: textureClustersLoading,
    error: textureClustersError,
    data: textureClustersData,
  } = useQuery<reports.ReportTextureClusterResponse>(
    ReportTextureClustersQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  useEffect(() => {
    if (textureClustersData) {
      setTextureConfidence(computeDefaultMinConfidence(textureClustersData));
    }
  }, [textureClustersData]);

  if (
    loading ||
    error ||
    reportTexturePreferenceLoading ||
    reportTexturePreferenceError ||
    textureClustersLoading ||
    textureClustersError
  ) {
    return <LoadingScreen />;
  }

  const getMostImportantCluster = () => {
    const clusterGroups = groupBy(
      reportTexturePreferences.allRpTexturePreferences.nodes,
      'clusterIdx',
    );
    let highestClusterScore = 0;
    let highestClusterScoreId = '';
    for (const cluster in clusterGroups) {
      const currentScore = clusterGroups[cluster].reduce(
        (acc, obj) => acc + Math.abs(Number(obj.pqImpact)),
        0,
      );
      if (currentScore > highestClusterScore) {
        highestClusterScore = currentScore;
        highestClusterScoreId = cluster;
      }
    }
    return highestClusterScoreId;
  };

  return (
    <div className={styles.tabContainer}>
      <Grid container spacing={4}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <InputLabel id="market-map-zoom-label" shrink>
                <Tooltip
                  title={t('reports.texturesSummary.confidenceExplainer')}
                >
                  <IconButton size="large">
                    <InfoOutlinedIcon style={{ fontSize: 16, color: '#777' }} />
                  </IconButton>
                </Tooltip>
                {t('reports.texturesSummary.minConfidence')}
              </InputLabel>
              <Slider
                value={textureConfidence}
                getAriaValueText={() => 'hi'}
                onChange={(event, newValue: number) => {
                  setTextureConfidence(newValue);
                }}
                aria-labelledby="texture-map-confidence-label"
                valueLabelDisplay="auto"
                step={1}
                min={50}
                max={100}
                size="small"
              />
            </div>
            <ReportTextureCluster
              reportId={reportId}
              textureClusters={mapAndRegroupClusterTexturesMinConfidence(
                textureClustersData,
                textureConfidence,
              )}
              useTextureClusterName={useTextureClusterNames}
              setUseTextureClusterName={setUseTextureClusterNames}
              highestClusterScoreId={Number(getMostImportantCluster())}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                {!showTernaryPlots &&
                  showOriginalProductsToggle(
                    reportId,
                    parentReportId,
                    reportSummary,
                  ) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={displayParentReport}
                          onChange={(e) =>
                            setDisplayParentReport(e.target.checked)
                          }
                          name="displayParentReport"
                        />
                      }
                      label={
                        <Typography variant="caption">
                          {t('reports.prodSummary.displayOriginalProducts')}
                        </Typography>
                      }
                    />
                  )}
              </Grid>
              <Grid item>
                <Tooltip title="Texture Map" placement="top">
                  <IconButton
                    onClick={() => {
                      setShowTernaryPlots(false);
                    }}
                  >
                    <RadioButtonUnchecked fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Ternary Plots" placement="top">
                  <IconButton
                    onClick={() => {
                      setShowTernaryPlots(true);
                    }}
                  >
                    <ChangeHistory fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {!showTernaryPlots && (
              <ReportTextureMap
                projectId={projectId}
                reportId={reportId}
                viewerUserId={viewerUserId}
                workspaceId={workspaceId}
                showTitle={false}
                showLegend={false}
                excludedProducts={excludedProducts}
                pageUpdated={false}
                textureMapOptions={textureMapOptions}
                setTextureMapOptions={setTextureMapOptions}
                parentReportId={displayParentReport ? parentReportId : null}
                useTextureClusterName={useTextureClusterNames}
                setExcludedProducts={() => new ProductVersionSet()}
                groupedTextureClusters={mapAndRegroupClusterTexturesMinConfidence(
                  textureClustersData,
                  textureConfidence,
                )}
              />
            )}
            {showTernaryPlots && (
              <ReportTextureTernary
                projectId={projectId}
                reportId={reportId}
                useTextureClusterName={useTextureClusterNames}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <ReportTexturePreference
            projectId={projectId}
            reportId={reportId}
            useTextureClusterName={useTextureClusterNames}
            highestClusterScoreId={Number(getMostImportantCluster())}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            {t('reports.texturePreference.detailedExplanation')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderBottomWidth: 2, borderColor: 'info.main' }} />
        </Grid>
        <Grid item xs={11}>
          <ReportTextureComposition
            projectId={projectId}
            reportId={reportId}
            useTextureClusterName={useTextureClusterNames}
            highestClusterScoreId={Number(getMostImportantCluster())}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            {t('reports.textureComposition.detailedExplanation')}
          </Typography>
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>
    </div>
  );
};

export default ProjectTextureSummary;
