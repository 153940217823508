import * as React from 'react';
import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import GastrographRadarQuery from '@graphql/queries/GastrographRadarQuery';
import ReportSummaryQueryForComparison from '@graphql/queries/ReportProductFlavorDecomps';
import { Masonry } from '@mui/lab';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { getProfileIntensity } from 'components/Report/ReportGastrographRadar/RadarUtils';
import { keyBy, Dictionary, keys, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import FlavorDecompositionComparisonCsv from './FlavorDecompositionComparisonCsv';
import FlavorCompositionTable from './FlavorDecompositionComparisonTable';
import styles from './FlavorDecompositionComparisonTable.module.css';

interface ReportProps {
  firstReportId: string;
  firstProductId: number;
  firstProductDisplayName: string;
  secondReportId: string;
  secondProductId: number;
  secondProductDisplayName: string;
  firstVersion: string;
  secondVersion: string;
}

export const mergeResults = (
  originalProduct: reports.MergedReportProductFlavorDecompsRow[],
  secondProduct: reports.MergedReportProductFlavorDecompsRow[],
): Dictionary<reports.FlavorDecompValues[]> => {
  const aKeyedBy: Dictionary<reports.MergedReportProductFlavorDecompsRow> =
    keyBy(originalProduct, 'mergeId');
  const bKeyedBy: Dictionary<reports.MergedReportProductFlavorDecompsRow> =
    keyBy(secondProduct, 'mergeId');

  const allKeys = Array.from(
    new Set([...keys(aKeyedBy), ...keys(bKeyedBy)].values()),
  );

  const results: reports.FlavorDecompValues[] = allKeys.map((key) => {
    const ggVar: string = aKeyedBy[key]
      ? aKeyedBy[key].ggVars
      : bKeyedBy[key].ggVars;
    const referenceFlavor: string = aKeyedBy[key]
      ? aKeyedBy[key].referenceFlavor
      : bKeyedBy[key].referenceFlavor;

    return {
      mergeId: key,
      ggVars: ggVar,
      referenceFlavor: referenceFlavor,
      decomp: aKeyedBy[key] ? parseFloat(aKeyedBy[key].decomp) : 0,
      absDecomp: aKeyedBy[key] ? parseFloat(aKeyedBy[key].absDecomp) : 0,
      sdecomp: bKeyedBy[key] ? parseFloat(bKeyedBy[key].decomp) : 0,
      sabsDecomp: bKeyedBy[key] ? parseFloat(bKeyedBy[key].absDecomp) : 0,
      absDeltaDecomp: Math.abs(
        (aKeyedBy[key] ? parseFloat(aKeyedBy[key].absDecomp) : 0) -
          (bKeyedBy[key] ? parseFloat(bKeyedBy[key].absDecomp) : 0),
      ),
    };
  });

  return groupBy(results, 'ggVars');
};

const renderEmptyList = () => {
  return (
    <div className={styles.emptyMain}>
      <div className={styles.emptySub}>
        <h5>Your selection produced an empty list!</h5>
      </div>
    </div>
  );
};

const ProductTitle: React.FC<{ title: string }> = ({ title }) => (
  <Tooltip title={title}>
    <span>{`"${title}"`}</span>
  </Tooltip>
);

const FlavorDecompositionComparison: React.FC<ReportProps> = (props) => {
  const {
    firstReportId,
    firstProductId,
    firstProductDisplayName,
    secondReportId,
    secondProductId,
    secondProductDisplayName,
    firstVersion,
    secondVersion,
  } = props;

  const { t } = useTranslation();

  const [flavorComparisonData, setFlavorComparisonData] = useState<
    [string, reports.FlavorDecompValues[]][]
  >([]);
  const [selectionCounter, setSelectionCounter] = useState<number>(0);

  const {
    loading: firstReportLoading,
    error: firstReportError,
    data: originalReportComparisonSummary,
  } = useQuery<reports.ReportProductFlavorDecomps>(
    ReportSummaryQueryForComparison,
    {
      variables: {
        reportID: firstReportId,
        productID: firstProductId,
        version: firstVersion,
      },
    },
  );

  const {
    loading: secondReportLoading,
    error: secondReportError,
    data: secondReportComparisonSummary,
  } = useQuery<reports.ReportProductFlavorDecomps>(
    ReportSummaryQueryForComparison,
    {
      variables: {
        reportID: secondReportId,
        productID: secondProductId,
        version: secondVersion,
      },
    },
  );

  const {
    loading: firstReportGastrographRadarLoading,
    error: firstReportGastrographRadarError,
    data: firstReportGastrographRadarResponse,
  } = useQuery<reports.ReportGastrographRadarReponse>(GastrographRadarQuery, {
    variables: {
      reportID: firstReportId,
      productID: firstProductId,
    },
  });

  const {
    loading: secondReportGastrographRadarLoading,
    error: secondReportGastrographRadarError,
    data: secondReportGastrographRadarResponse,
  } = useQuery<reports.ReportGastrographRadarReponse>(GastrographRadarQuery, {
    variables: {
      reportID: secondReportId,
      productID: secondProductId,
    },
  });

  useEffect(() => {
    if (
      !firstReportLoading &&
      !firstReportError &&
      !secondReportLoading &&
      !secondReportError
    ) {
      const formattedOriginalProduct =
        originalReportComparisonSummary.allRpFlavorDecomps.nodes.map(
          (item) => ({
            ...item,
            mergeId: `${item.ggVars}${item.referenceFlavor}`,
          }),
        );
      const formattedSecondProduct =
        secondReportComparisonSummary.allRpFlavorDecomps.nodes.map((item) => ({
          ...item,
          mergeId: `${item.ggVars}${item.referenceFlavor}`,
        }));

      if (formattedOriginalProduct.length && formattedSecondProduct.length) {
        const flavorData = mergeResults(
          formattedOriginalProduct,
          formattedSecondProduct,
        );

        setFlavorComparisonData(Object.entries(flavorData));

        // annoying hack to get the tables to rerender
        setSelectionCounter((current) => current + 1);
      }
    }
  }, [originalReportComparisonSummary, secondReportComparisonSummary]);

  if (
    firstReportLoading ||
    firstReportError ||
    secondReportLoading ||
    secondReportError ||
    firstReportGastrographRadarLoading ||
    firstReportGastrographRadarError ||
    secondReportGastrographRadarLoading ||
    secondReportGastrographRadarError
  ) {
    return <LoadingScreen />;
  }

  if (
    !flavorComparisonData.length ||
    !originalReportComparisonSummary ||
    !secondReportComparisonSummary
  ) {
    return renderEmptyList();
  }

  const decompSum = originalReportComparisonSummary?.allRpFlavorDecomps?.nodes
    .filter((r) => !r.referenceFlavor.startsWith('generic'))
    .map((r) => parseFloat(r.decomp))
    .reduce((a, b) => a + b, 0);
  const sdecompSum = secondReportComparisonSummary?.allRpFlavorDecomps?.nodes
    .filter((r) => !r.referenceFlavor.startsWith('generic'))
    .map((r) => parseFloat(r.decomp))
    .reduce((a, b) => a + b, 0);

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <div className={styles.pageHeader}>Known Signatures account for:</div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.pageHeader}>
            <div style={{ display: 'inline-block' }}>
              <ProductTitle title={firstProductDisplayName} />
              &nbsp;
            </div>
            <div style={{ display: 'inline-block' }}>&nbsp;|&nbsp;</div>
            <div style={{ display: 'inline-block' }}>
              <ProductTitle title={secondProductDisplayName} />
              &nbsp;
            </div>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <div className={styles.pageHeader}>
            <div style={{ display: 'inline-block' }}>
              <div style={{ display: 'inline-block', paddingRight: 2 }}>Flavor Profile:</div>
              <div style={{ display: 'inline-block', paddingRight: 6 }}>
              {(decompSum * 100).toFixed(0)}%&nbsp; -
            </div>
              Flavor Profile Intensity:{' '}
              {getProfileIntensity(
                firstReportGastrographRadarResponse,
                firstVersion,
              ).toFixed(0)}
            </div>
            <div style={{ display: 'inline-block' }}>&nbsp;|&nbsp;</div>
            <div style={{ display: 'inline-block' }}>
              <div style={{ display: 'inline-block', paddingRight: 2 }}>Flavor Profile:</div>
              <div style={{ display: 'inline-block', paddingRight: 6 }}>
              {(sdecompSum * 100).toFixed(0)}%&nbsp; -
            </div>
              Flavor Profile Intensity:{' '}
              {getProfileIntensity(
                secondReportGastrographRadarResponse,
                secondVersion,
              ).toFixed(0)}
            </div>
          </div>
        </Grid>
        <Grid item xs={1}>
          <FlavorDecompositionComparisonCsv
            flavorComparisonData={flavorComparisonData}
            firstProductDisplayName={firstProductDisplayName}
            secondProductDisplayName={secondProductDisplayName}
          />
        </Grid>
      </Grid>
      <Masonry columns={2} spacing={2}>
        {flavorComparisonData.map((item) => (
          <FlavorCompositionTable
            {...props}
            key={`${item[0]}_${firstProductDisplayName}_${secondProductDisplayName}_${selectionCounter}`}
            ggVar={item[0]}
            flavorComparisons={item[1]}
            firstProductDisplayName={firstProductDisplayName}
            secondProductDisplayName={secondProductDisplayName}
          />
        ))}
      </Masonry>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="overline" gutterBottom>
            {t('reports.fpDecomp.legendComparison')}
          </Typography>
        </Box>
      </Grid>
    </div>
  );
};

export default FlavorDecompositionComparison;
