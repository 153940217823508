import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  useTheme,
} from '@mui/material';
import { Optional } from 'typescript-optional';

import useStyles from '../useStyles';

interface Props {
  projectId: number;
  reportId: string;
}

interface Percentiles {
  type: string;
  pq: string;
}

export const mapData = (
  norms: reports.ReportPQNormsResponse,
): Percentiles[] => {
  return Optional.ofNullable(norms)
    .map((n) => n.allRpCategoryNorms)
    .map((arn) => arn.nodes)
    .map((i) => i[0])
    .map((r) => [
      {
        type: '25th Percentile',
        pq: r.q1,
      },
      {
        type: '50th Percentile',
        pq: r.median,
      },
      {
        type: '75th Percentile',
        pq: r.q3,
      },
    ])
    .orElse([]);
};

const CategoryNorms: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const classes = useStyles(useTheme());

  const { loading, error, data } = useQuery<reports.ReportPQNormsResponse>(
    ReportPQNormsQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (loading) {
    return <Skeleton variant="rectangular" height={400} animation="wave" />;
  }

  if (error || mapData(data).length !== 3) {
    return <></>;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader title="Category Norms" className={classes.header} />
        <ul>
          {mapData(data).map((r: Percentiles) => (
            <li key={r.type}>
              {r.type}:{' '}
              {!isNaN(parseFloat(r.pq)) ? parseFloat(r.pq).toFixed(2) : null}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default CategoryNorms;
