import { myProfilePng } from 'constants/images';

import * as React from 'react';
import { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import NotificationsNoneTwoTone from '@mui/icons-material/NotificationsNoneTwoTone';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import {
  StyledContainerDiv,
  menuSectionClassName,
  notificationSectionClassName,
} from './StyledComponents';
import useStyles from './useStyles';
import UserNotificationsQuery from '../../graphql/queries/UserNotificationsQuery';

interface Props {
  onClickCloseMenu: () => any;
  onClickOpenMenu: () => any;
  onClickCloseNotification: () => any;
  onClickOpenNotification: () => any;
  onClickOpenGlossary: () => any;
  onClickCloseGlossary: () => any;
  menuIsOpen: boolean;
  notificationIsOpen: boolean;
  glossaryIsOpen: boolean;
  renderMenuContents: (params: {
    menuSectionClassName: string;
  }) => ReactElement[];
  renderNotificationContents: (params: {
    notificationSectionClassName: string;
  }) => any;
  viewerId?: number;
  viewerEmail?: string;
  viewerName?: string;
  viewerIsAuthenticated: boolean;
  workspaceProducerId?: number;
}

const AppViewerMenu: React.FunctionComponent<Props> = (props) => {
  const {
    onClickCloseMenu,
    onClickOpenMenu,
    onClickCloseNotification,
    onClickOpenNotification,
    onClickOpenGlossary,
    onClickCloseGlossary,
    menuIsOpen,
    notificationIsOpen,
    glossaryIsOpen,
    renderMenuContents,
    renderNotificationContents,
    viewerId,
    viewerEmail,
    viewerName,
    viewerIsAuthenticated,
    workspaceProducerId,
    ...rest
  } = props;

  const classes = useStyles(useTheme());
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(UserNotificationsQuery, {
    variables: {
      producerId: workspaceProducerId ?? 0,
      active: true,
    },
  });

  const totalNotifications =
    data &&
    data.notifications &&
    Array.isArray(data.notifications.nodes) &&
    data.notifications.nodes.length;

  if (!viewerIsAuthenticated) {
    return null;
  }

  return (
    <StyledContainerDiv {...rest}>
      <Modal
        title="Glossary"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
        open={glossaryIsOpen}
        onClose={() => {
          onClickCloseGlossary(),
            onClickCloseNotification(),
            onClickCloseMenu();
        }}
      >
        <div
          style={{
            flex: 1,
            background: 'white',
            height: '75%',
            maxWidth: '75%',
            overflow: 'scroll',
            padding: '15px',
          }}
        >
          <div
            style={{
              fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
              fontSize: 24,
              color: '#022950',
            }}
          >
            {t('glossary.ggVarTitle')}
          </div>
          <div
            style={{
              fontSize: 12,
              fontFamily: 'OpenSans, sans-serif',
              color: '#022950',
            }}
          >
            <p>{t('glossary.astringent')}</p>
            <p>{t('glossary.bitter')}</p>
            <p>{t('glossary.coldFinish')}</p>
            <p>{t('glossary.dairy')}</p>
            <p>{t('glossary.dry')}</p>
            <p>{t('glossary.earthy')}</p>
            <p>{t('glossary.floral')}</p>
            <p>{t('glossary.fruit')}</p>
            <p>{t('glossary.gamey')}</p>
            <p>{t('glossary.herbaceous')}</p>
            <p>{t('glossary.marine')}</p>
            <p>{t('glossary.meaty')}</p>
            <p>{t('glossary.mineral')}</p>
            <p>{t('glossary.mouthfeel')}</p>
            <p>{t('glossary.nutsAndSeeds')}</p>
            <p>{t('glossary.retronasal')}</p>
            <p>{t('glossary.rich')}</p>
            <p>{t('glossary.roasted')}</p>
            <p>{t('glossary.smoked')}</p>
            <p>{t('glossary.sourAndAcidity')}</p>
            <p>{t('glossary.spice')}</p>
            <p>{t('glossary.sugar')}</p>
            <p>{t('glossary.wet')}</p>
            <p>{t('glossary.woody')}</p>
          </div>
          <div
            style={{
              fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
              fontSize: 24,
              color: '#022950',
            }}
          >
            {t('glossary.glossaryTitle')}
          </div>
          <div
            style={{
              fontSize: 12,
              fontFamily: 'OpenSans, sans-serif',
              color: '#022950',
            }}
          >
            <p>{t('glossary.branch')}</p>
            <p>{t('glossary.categoryNorm')}</p>
            <p>{t('glossary.driversOfPreference')}</p>
            <p>{t('glossary.experienceRange')}</p>
            <p>{t('glossary.ggVar')}</p>
            <p>{t('glossary.pq')}</p>
            <p>{t('glossary.polarization')}</p>
            <p>{t('glossary.preferenceDistribution')}</p>
            <p>{t('glossary.referenceFlavor')}</p>
            <p>{t('glossary.signature')}</p>
            <p>{t('glossary.socioEcon')}</p>
            <p>{t('glossary.stackRankNorms')}</p>
            <p>{t('glossary.targetDemo')}</p>
            <p>{t('glossary.tc')}</p>
            <p>{t('glossary.textureComposition')}</p>
            <p>{t('glossary.trunk')}</p>
            <p>{t('glossary.winRate')}</p>
          </div>
        </div>
      </Modal>

      {menuIsOpen && (
        <div className="backdrop" onClick={onClickCloseMenu} tabIndex={-1} />
      )}
      <div className={classes.glossaryIcon}>
        <IconButton
          className={classes.glossaryIsOpen}
          onClick={
            glossaryIsOpen
              ? onClickCloseGlossary
              : () => {
                  onClickOpenGlossary();
                  onClickCloseNotification();
                  onClickCloseMenu();
                }
          }
          tabIndex={-1}
          size="large"
        >
          <Badge color="secondary" overlap="rectangular">
            <InfoOutlinedIcon style={{ fontSize: 32, color: 'white' }} />
          </Badge>
        </IconButton>
      </div>
      <div key="noticationIcon" className="notificationIcon">
        <IconButton
          className="toggleNotificationIsOpenButton"
          onClick={
            notificationIsOpen
              ? onClickCloseNotification
              : () => {
                  onClickOpenNotification();
                  onClickCloseMenu();
                }
          }
          tabIndex={-1}
          size="large"
        >
          <Badge
            color="secondary"
            badgeContent={totalNotifications}
            overlap="rectangular"
          >
            <NotificationsNoneTwoTone
              style={{ fontSize: 32, color: 'white' }}
            />
          </Badge>
        </IconButton>
        <div className={`notificationWrapper ${notificationIsOpen && 'open'}`}>
          <div className="notification">
            {renderNotificationContents({
              notificationSectionClassName,
            })}
          </div>
        </div>
      </div>

      <div key="relativeWrapper" className="relativeWrapper">
        <a
          className="toggleMenuIsOpenButton"
          onClick={
            menuIsOpen
              ? onClickCloseMenu
              : () => {
                  onClickOpenMenu();
                  onClickCloseNotification();
                }
          }
          tabIndex={-1}
        >
          <Avatar src={myProfilePng} className={classes.root} />
          <span className={classes.usernameText}>{viewerName}</span>
          <KeyboardArrowDown color="secondary" />
        </a>
        <div className={`menuWrapper ${menuIsOpen && 'open'}`}>
          <div className="menu">
            {renderMenuContents({
              menuSectionClassName,
            })}
          </div>
        </div>
      </div>
    </StyledContainerDiv>
  );
};

AppViewerMenu.displayName = 'AppViewerMenu';

export default AppViewerMenu;
