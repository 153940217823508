import { useQuery } from '@apollo/client';
import { FIND_REPORT_ID_QUERY } from '@graphql/queries/AllReportIDsQuery';
import { Box, TextField, Typography } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { nvlObject } from 'utils/afsUtils';
import { validate } from 'uuid';

export const SuperAdminOptions = ({
  change,
  report_id_to_inherit_parent_data_sources,
  displayMode,
}) => {
  const { data, loading } = useQuery(FIND_REPORT_ID_QUERY, {
    variables: { reportID: report_id_to_inherit_parent_data_sources },
    skip: !validate(report_id_to_inherit_parent_data_sources),
  });

  const isValid = (inputReportID: string) =>
    validate(inputReportID) &&
    data.allReportJobs.nodes.some(
      (report) => report.reportId === inputReportID,
    );

  if (loading) return <LoadingScreen />;

  return (
    <Box>
      <TextField
        disabled={displayMode}
        value={nvlObject<string>(
          report_id_to_inherit_parent_data_sources,
          null,
        )}
        onChange={(e) => {
          change('report_id_to_inherit_parent_data_sources', e.target.value);
        }}
        label="Report ID to Inherit Parent Data Sources"
        fullWidth
        error={!isValid(report_id_to_inherit_parent_data_sources)}
        helperText={
          !isValid(report_id_to_inherit_parent_data_sources)
            ? 'Invalid report ID'
            : ''
        }
      />
      {isValid(report_id_to_inherit_parent_data_sources) && (
        <Box>
          <Typography>
            {data.allReportJobs.nodes[0].clientName +
              ' | ' +
              data.allReportJobs.nodes[0].projectName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
