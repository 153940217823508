import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportTexturePreferenceQuery from '@graphql/queries/ReportTexturePreferenceQuery';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import { Box, Card, CardContent, CardHeader, Skeleton, useTheme } from '@mui/material';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import useStyles from '../useStyles';

export const getMostImportantCluster = (
  reportTexturePreferences: reports.ReportTexturePreferencesResponse,
) => {
  const clusterGroups = groupBy(
    reportTexturePreferences.allRpTexturePreferences.nodes,
    'clusterIdx',
  );
  let highestClusterScore = 0;
  let highestClusterScoreId = '';
  for (const cluster in clusterGroups) {
    const currentScore = clusterGroups[cluster].reduce(
      (acc, obj) => acc + Math.abs(Number(obj.pqImpact)),
      0,
    );
    if (currentScore > highestClusterScore) {
      highestClusterScore = currentScore;
      highestClusterScoreId = cluster;
    }
  }
  return highestClusterScoreId;
};

interface Props {
  projectId: number;
  reportId: string;
}

const TextureExecutiveSummary: React.FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: reportTexturePreferenceLoading,
    error: reportTexturePreferenceError,
    data: reportTexturePreferences,
  } = useQuery<reports.ReportTexturePreferencesResponse>(
    ReportTexturePreferenceQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (reportTexturePreferenceError || tcNameError) {
    return <></>;
  }

  if (reportTexturePreferenceLoading || tcNameLoading) {
    return <Skeleton variant="rectangular" height={400} animation="wave" />;
  }

  const getTCNameByIndex = (index: number) =>
    tcNameData.allRpTextureClusterNames.nodes.find(
      (x) => x.clusterIdx === index,
    )?.clusterName;

  const mostImportantTc = getTCNameByIndex(
    Number(getMostImportantCluster(reportTexturePreferences)),
  );
  if (mostImportantTc == null) {
    return <Box />;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader title="Texture Summary" className={classes.header} />
        <li>
          <span>
            {t('reports.texturesSummary.importantTCText', {
              tcName: mostImportantTc,
            })}
          </span>
        </li>
      </CardContent>
    </Card>
  );
};

export default TextureExecutiveSummary;
