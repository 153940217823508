import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsQuery from '@graphql/queries/OrderOfOperationsQuery';
import ReportStackRankComparisonQuery from '@graphql/queries/ReportStackRankComparisonQuery';
import { Card, CardContent, CardHeader, Skeleton, useTheme } from '@mui/material';
import { chain } from 'lodash';
import { useTranslation } from 'react-i18next';

import { cleanUnderscore } from '../../../utils/afsUtils';
import useStyles from '../useStyles';

interface Props {
  projectId: number;
  reportId: string;
}

export const getMostCommonFlavor = (
  comparisonData: reports.ReportStackRankComparison,
): string[] => {
  if (
    !comparisonData ||
    !comparisonData.allRpFlavorDecomps ||
    !comparisonData.allRpFlavorDecomps.nodes
  )
    return [];

  const flavors = comparisonData.allRpFlavorDecomps.nodes.map((n) => ({
    flavor: n.referenceFlavor,
    absDecomp: n.absDecomp,
  }));
  const loTest = chain(flavors).groupBy('flavor');
  const test = loTest.value();
  const rfCounts: { [flavor: string]: number } = {};
  Array.from(Object.keys(test)).forEach(
    (key) => (rfCounts[key] = flavors.filter((f) => f.flavor == key).length),
  );
  const test2 = Object.keys(rfCounts).filter(
    (flavor) => rfCounts[flavor] == Math.max(...Object.values(rfCounts)),
  );

  const a = Object.entries(test).reduce(
    (accu, cur) => {
      const key = cur[0];
      const arr = cur[1];
      const maxDecomp = Math.max(...arr.map((i) => parseFloat(i.absDecomp)));

      if (accu.absDecomp) {
        return accu.absDecomp > maxDecomp
          ? accu
          : { flavor: key, absDecomp: maxDecomp };
      } else {
        return { flavor: key, absDecomp: maxDecomp };
      }
    },
    {} as { flavor: string; absDecomp: number },
  );
  return Object.keys(a).length === 0 ? [] : Array.of(a.flavor);
};

const ActionSummary: React.FC<Props> = (props) => {
  const { reportId } = props;

  const classes = useStyles(useTheme());
  const { t } = useTranslation();

  const {
    loading: comparisonLoading,
    error: comparisonError,
    data: comparisonData,
  } = useQuery<reports.ReportStackRankComparison>(
    ReportStackRankComparisonQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    data: optStepsResponse,
    loading: optStepsLoading,
    error: optStepsError,
  } = useQuery<reports.OrderOfOperationsData>(OrderOfOperationsQuery, {
    variables: { reportID: reportId },
  });

  const getBestDeltaPQStep = (
    optStepsResponse: reports.OrderOfOperationsData,
  ): reports.OrderOfOperationNode[] => {
    return optStepsResponse.allRpOptSteps.nodes.reduce(
      (accu, item) =>
        accu.length > 0
          ? +item.deltaPq > +accu.find(Boolean).deltaPq
            ? [item]
            : accu
          : [item],
      [],
    );
  };

  const getLargestDeltaGGVarStep = (
    optStepsResponse: reports.OrderOfOperationsData,
  ): reports.OrderOfOperationNode[] => {
    return optStepsResponse.allRpOptSteps.nodes
      .filter((d) => !!d.ggvar)
      .reduce(
        (accu, item) =>
          accu.length > 0
            ? +item.deltaSignature > +accu.find(Boolean).deltaSignature
              ? [item]
              : accu
            : [item],
        [],
      );
  };

  if (optStepsLoading || comparisonLoading) {
    return <Skeleton variant="rectangular" height={400} animation="wave" />;
  }

  if (optStepsError || comparisonError) {
    return <></>;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader
          title={t('reports.actionSummary.title')}
          className={classes.header}
        />
        <ul>
          <li key="mostCommonFlavor">
            {t('reports.actionSummary.mostCommonFlavor') +
              ` ${getMostCommonFlavor(comparisonData)
                .map(cleanUnderscore)
                .join(', ')}`}
          </li>
          {getBestDeltaPQStep(optStepsResponse).length > 0 && (
            <li key="bestPQChange">
              {t('reports.actionSummary.bestPQChange') +
                ` ${getBestDeltaPQStep(optStepsResponse)
                  .map((i) => cleanUnderscore(i.refFlavor))
                  .join(', ')}`}
            </li>
          )}
          {getLargestDeltaGGVarStep(optStepsResponse).length > 0 && (
            <li key="importantGGVarChange">
              {t('reports.actionSummary.importantGGVarChange') +
                ` ${getLargestDeltaGGVarStep(optStepsResponse)
                  .map((i) => i.ggvar)
                  .join(', ')}`}
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  );
};

export default ActionSummary;
