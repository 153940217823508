import { OPTIMIZATION } from 'constants/report';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsCountQuery from '@graphql/queries/OrderOfOperationsCountQuery';
import { Grid, Box } from '@mui/material';
import ActionSummary from 'components/ExecutiveSummary/ActionSummary';
import CategoryNorms from 'components/ExecutiveSummary/CategoryNorms';
import OptimizationTop3Steps from 'components/ExecutiveSummary/OptimizationTop3Steps';
import PositiveNegativeDrivers from 'components/ExecutiveSummary/PositiveNegativeDrivers';
import StackRankPqRange from 'components/ExecutiveSummary/StackRankPqRange';
import TextureExecutiveSummary from 'components/ExecutiveSummary/TextureExecutiveSummary';
import LoadingScreen from 'components/LoadingScreen';
import FlavorSummaryAttributeIntensity from 'components/Report/FlavorSummaryAttribute';
import StackRankDriversTable from 'components/Report/StackRankComparison';
import {
  showStackRankDrivers,
  showTopThreeOpt,
  useCustomerPreferences,
} from 'services/customerPreferences';
import { nvlReportId } from 'utils/afsUtils';

interface Props {
  projectId: number;
  projectReports: reports.ProjectReportSummary[];
  selectedReportId: string;
}

const ProjectExecutiveSummary: React.FC<Props> = (props: Props) => {
  const { selectedReportId, projectReports, projectId } = props;

  const customerPreferences = useCustomerPreferences();

  const isOptimizationReport = (
    projectReports: reports.ProjectReportSummary[],
    selectedReportId: string,
  ): boolean => {
    return (
      projectReports.find((i) => i.reportId === selectedReportId).reportType ===
      OPTIMIZATION
    );
  };

  const { data: optSummaryCnt, loading: loadingOptSummaryCnt } =
    useQuery<reports.ReportOrderOfOperationsCountResponse>(
      OrderOfOperationsCountQuery,
      {
        variables: {
          reportID: nvlReportId(selectedReportId),
        },
      },
    );

  if (loadingOptSummaryCnt) {
    return <LoadingScreen />;
  }

  return (
    selectedReportId && (
      <Grid
        flexDirection={'column'}
        justifyContent={'flex-start'}
        container
        spacing={2}
        style={{ marginTop: 50 }}
        key={selectedReportId}
      >
        <Grid
          flexDirection={'column'}
          flexWrap={'wrap'}
          gap={2}
          maxHeight={
            isOptimizationReport(projectReports, selectedReportId)
              ? '60em'
              : '40em'
          }
          alignContent={'space-between'}
          sx={{
            '& > .MuiBox-root': {
              width: '50%',
              '&:nth-child(2n+1)': { order: 1 }, // for 1, 3, 5 in first col
              '&:nth-child(2n)': { order: 2 }, // for 2, 4, 6 in second col
            },
          }}
          item
          container
        >
          {showTopThreeOpt(customerPreferences) &&
            isOptimizationReport(projectReports, selectedReportId) &&
            optSummaryCnt &&
            (optSummaryCnt?.allRpOptSteps?.totalCount ?? 0) > 0 && (
              <Box>
                <OptimizationTop3Steps
                  projectId={projectId}
                  reportId={selectedReportId}
                />
              </Box>
            )}
          {isOptimizationReport(projectReports, selectedReportId) && (
            <Box>
              <ActionSummary
                projectId={projectId}
                reportId={selectedReportId}
              />
            </Box>
          )}
          {!isOptimizationReport(projectReports, selectedReportId) && (
            <Box>
              <PositiveNegativeDrivers
                projectId={projectId}
                reportId={selectedReportId}
              />
            </Box>
          )}
          <Box>
            <StackRankPqRange
              projectId={projectId}
              reportId={selectedReportId}
            />
          </Box>
          <Box>
            <CategoryNorms projectId={projectId} reportId={selectedReportId} />
          </Box>
          <Box>
            <TextureExecutiveSummary
              projectId={projectId}
              reportId={selectedReportId}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FlavorSummaryAttributeIntensity
            projectId={projectId}
            reportId={selectedReportId}
          />
        </Grid>
        {!isOptimizationReport(projectReports, selectedReportId) &&
          showStackRankDrivers(customerPreferences) && (
            <Grid item xs={12} marginTop={10}>
              <StackRankDriversTable
                projectId={projectId}
                reportId={selectedReportId}
              />
            </Grid>
          )}
      </Grid>
    )
  );
};

export default ProjectExecutiveSummary;
