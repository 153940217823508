import ggVars from 'constants/ggVars';

import * as React from 'react';
import { Fragment } from 'react';

import { Paper, Step, StepLabel, Stepper } from '@mui/material';
import useAvailableCountries from 'hooks/useAvailableCountries';
import useGgEnvironment from 'hooks/useGgEnvironment';
import useRegionsList from 'hooks/useRegionsList';
import useReportSummary from 'hooks/useReportSummary';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { arrayify } from 'utils/afsUtils';
import { jobDefinition } from 'utils/conchUtils';
import { isProd } from 'utils/environmentUtils';

import AdvancedConfig from './AdvancedConfig/AdvancedConfig';
import ClassDBTable from './ClassDBTable';
import Confirmation from './Confirmation/Confirmation';
import Demographics from './Demographics';
import MarketSurvey from './MarketSurvey/MarketSurvey';
import Optimization from './Optimization';
import ReportType from './ReportType/ReportType';
import styles from './RequestReport.module.css';
import RequestSent from './RequestSent';
import { invalidFields } from './utils';
import MaterialButton from '../../components/MaterialButton';
import StepperIcon from '../../components/StepperIcon';
import { REQUEST_REPORT_FORM } from '../../constants/formNames';
import {
  REQUEST_REPORT_NEXT,
  REQUEST_REPORT_PREV,
  REQUEST_REPORT_SUBMIT,
} from '../../constants/googleAnalytics/actions';
import { CAT_REQUEST_REPORT } from '../../constants/googleAnalytics/categories';
import { MARKET_SURVEY, OPTIMIZATION, PRODUCT } from '../../constants/report';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import ConditionViewerRoleContainer from '../ConditionViewerRole';

const { event } = ReactGA;

const STEPS = [
  'Select Report Type',
  'Target Demographics',
  'Competitive Set',
  'Advanced Configuration',
  'Confirm',
];

const RequestReport: React.FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { availableCountries, loading } = useAvailableCountries(producerId);
  const regions = useRegionsList();
  const environment = useGgEnvironment();

  const formValues = useSelector((state) =>
    getFormValues(REQUEST_REPORT_FORM)(state),
  );
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [submitted, setSubmitted] = React.useState(false);
  const [openClassDB, setOpenClassDB] = React.useState<boolean>(false);
  const [templateReport, setTemplateReport] = React.useState<any>(null);

  const formProps = { ...props, ...formValues };

  const {
    competitive_set_rich,
    competitive_set_folders,
    report_type,
    change,
    pristine,
    invalid,
    submitting,
    submitFailed,
    limitExceeded,
    submitSucceeded,
    submittedProjectName,
    submittedType,
    handleSubmit,
    displayMode = false,
  } = formProps;

  React.useEffect(() => {
    const state: any = location?.state;
    const initStep: string = state?.initStep;
    if (initStep) setActiveStep(Number(initStep));
    const templateReport = state?.templateReport;
    setTemplateReport(templateReport);
  }, [location]);

  const templateReportId = (location?.state as any)?.templateReport?.reportId;
  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    productVersionSet,
  } = useReportSummary(templateReportId, { skip: !templateReportId });

  React.useEffect(() => {
    change('job_definition', jobDefinition(environment));
  }, [environment]);

  React.useEffect(() => {
    if (templateReport) {
      const temp = templateReport;
      change('project_name', temp.projectName);
      change('client_name', {
        label: temp.clientName,
        value: temp.clientName,
      });
      change('workspace_id', {
        id: temp.workspaceId,
        name: temp.producerByWorkspaceId.name,
        label: temp.producerByWorkspaceId.name,
      });
      change('report_type', {
        value: temp.reportType,
        // copied from en.ts
        label:
          temp.reportType == 'market_survey'
            ? 'Market Survey Report'
            : 'Optimization Report',
      });
      const templateCountry = Array.from(availableCountries).find(
        (c) => c.dbname === temp.params.countries,
      );

      change(
        'countries',
        templateCountry
          ? {
            label: `${templateCountry.emoji} ${templateCountry.name}`,
            value: templateCountry.dbname,
          }
          : null,
      );
      change('clts', temp.params.clts ?? []);
      change('include_female', temp.params.include_female);
      change('asian', temp.params.asian);
      change('black', temp.params.black);
      change('hispanic', temp.params.hispanic);
      change('white', temp.params.white);
      change(
        'usa_region',
        temp.params.usa_region
          ? { label: temp.params.usa_region, value: temp.params.usa_region }
          : null,
      );
      change('ageCategory', temp.params.ageCategory);
      change('age_ranges', temp.params.age_ranges);
      (temp.params.age_ranges ?? []).forEach((age_range, i) => {
        change(`age_range_weight_${i}`, age_range.fraction * 100);
      });
      change('smoking_habits', temp.params.smoking_habits);
      change('SansRejector', temp.params.SansRejector);
      change('only_include_parents', temp.params.only_include_parents);
      change('include_texture', temp.params.include_texture);
      change('texture_only', temp.params.texture_only);
      change('texture_data_only', temp.params.texture_data_only);
      change('showConfidence', temp.params.showConfidence);
      change('show_TC_se', temp.params.show_TC_se);
      change('include_archetypes', temp.params.include_archetypes);
      change('n_cluster_archetype', temp.params.n_cluster_archetype);
      change('n_cluster_archetype_max', temp.params.n_cluster_archetype_max);
      change('simulate_pq_values', temp.params.simulate_pq_values);
      change('n_sims', temp.params.n_sims);
      change('profilingEnabled', temp.params.profilingEnabled);
      change('generate_ppt', temp.params.generate_ppt);
      change('extrema_analysis', temp.params.extrema_analysis);
      change('includeNoveltyScores', temp.params.includeNoveltyScores);
      change('plotNorm', temp.params.plotNorm);
      change('show_pg_gNorm', temp.params.show_pg_gNorm);
      change(
        'combine_artificial_sweeteners',
        temp.params.combine_artificial_sweeteners,
      );
      change('override_invalid', temp.params.override_invalid);
      change('timestamp_start', temp.params.timestamp_start);
      change('experimental_features', temp.params.experimental_features);
      change('report_id_to_inherit_parent_data_sources', temp.params.report_id_to_inherit_parent_data_sources);
      change(
        'custom_class_db_rich',
        temp.params.custom_class_db_products.map((p: string) => ({ name: p })),
      );

      if (temp.reportType == 'optimization') {
        change('optimization.workspace_id', temp.workspaceId);
        change('optimization.artifact_path', temp.params.artifact_path);
        change('optimization.parent_job_id', temp.params.parent_job_id);
        change('optimization.client_name', temp.params.client_name);
        change('optimization.project_name', temp.params.project_name);
        change('optimization.target_group_name', temp.params.target_group_name);
        change('optimization.products_to_opt', {
          label: temp.params.products_to_opt,
          value: temp.params.products_to_opt,
        });
        const ggvars_to_lock = ggVars
          .filter((v) => [temp.params.ggvar_to_lock].flat().includes(v.key))
          .map((v) => ({
            label: v.label,
            value: v.key,
          }));
        change('optimization.ggvars_to_lock', ggvars_to_lock);
        change(
          'optimization.ggvar_constraints',
          [temp.params.ggvar_min_list]
            .flat()
            .map((d, i) => [d, [temp.params.ggvar_max_list].flat()[i]]),
        );
        change(
          'optimization.ref_flavor_to_lock',
          arrayify(temp.params.ref_flavor_to_lock).map((rf) => ({
            label: rf,
            value: rf,
          })),
        );
        change(
          'optimization.ref_flavor_to_remove',
          arrayify(temp.params.flavors_to_remove).map((rf) => ({
            label: rf,
            value: rf,
          })),
        );
        change(
          'optimization.ref_flavor_to_tune',
          arrayify(temp.params.rangeReff).map((rf) => ({
            label: rf,
            value: rf,
          })),
        );
        change(
          'optimization.rangeReffRange',
          arrayify(temp.params.rangeReffRange.max).map((d, i) => [
            temp.params.rangeReffRange.min[i] * 100,
            d * 100,
          ]),
        );
        change(
          'optimization.NRF',
          temp.params.new_reference_flavors_allowed.includes('TRUE'),
        );
        change(
          'optimization.NNRF',
          temp.params.new_reference_flavors_allowed.includes('FALSE'),
        );
        change(
          'optimization.NC_constraint',
          temp.params.constraint_levels.includes('NC'),
        );
        change(
          'optimization.ULC_constraint',
          temp.params.constraint_levels.includes('ULC'),
        );
        change(
          'optimization.VLC_constraint',
          temp.params.constraint_levels.includes('VLC'),
        );
        change(
          'optimization.LC_constraint',
          temp.params.constraint_levels.includes('LC'),
        );
        change(
          'optimization.MC_constraint',
          temp.params.constraint_levels.includes('MC'),
        );
        change(
          'optimization.HC_constraint',
          temp.params.constraint_levels.includes('HC'),
        );
        change(
          'optimization.VHC_constraint',
          temp.params.constraint_levels.includes('VHC'),
        );
        change(
          'optimization.UHC_constraint',
          temp.params.constraint_levels.includes('UHC'),
        );
        change('optimization.enable_norms', temp.params.show_pg_gNorm);
        change('optimization.tuning_plots', temp.params.show_tuning_plots);
        change('optimization.plot_norm', temp.params.plotNorm);
        change('optimization.optimize_flavor', temp.params.run_flavor_opt);
        change('optimization.optimize_texture', temp.params.run_texture_opt);
      }
    }
  }, [templateReport, loading]);

  const handleBackStep = () => {
    event({
      category: CAT_REQUEST_REPORT,
      action: REQUEST_REPORT_PREV,
      label: STEPS[activeStep],
    });

    if (report_type.value === OPTIMIZATION) {
      setActiveStep(activeStep - 2);
    }

    if (report_type.value === MARKET_SURVEY) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    event({
      category: CAT_REQUEST_REPORT,
      action: REQUEST_REPORT_NEXT,
      label: STEPS[activeStep],
    });

    if (report_type.value === OPTIMIZATION) {
      setActiveStep(activeStep + 2);
    }

    if (report_type.value === MARKET_SURVEY) {
      setActiveStep(activeStep + 1);
    }
  };

  if (report_type.value === OPTIMIZATION) {
    STEPS[2] = 'Optimization Target';
  }

  if (submitted) {
    return (
      <RequestSent
        submitFailed={submitFailed}
        limitExceeded={limitExceeded}
        submitSucceeded={submitSucceeded}
        submitting={submitting}
        report_type={submittedType && submittedType.value}
        project_name={submittedProjectName}
      />
    );
  }

  return (
    <Fragment>
      <Paper className={styles.container}>
        <Stepper
          style={{ padding: 24 }}
          activeStep={activeStep}
          nonLinear
          alternativeLabel
        >
          {STEPS.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && <ReportType change={change} />}

        {activeStep === 1 && report_type.value == MARKET_SURVEY && (
          <div>
            <Demographics
              displayMode={displayMode}
              allCountries={availableCountries}
              allRegions={regions}
              countries={formProps.initialValues.countries}
              include_female={formValues.include_female}
              change={change}
              {...formValues}
            />
          </div>
        )}

        {activeStep === 2 &&
          (report_type.value === MARKET_SURVEY ||
            report_type.value === PRODUCT) && (
            <MarketSurvey
              reportWorkspace={formValues.workspace_id}
              templateReportProductSet={productVersionSet}
              change={change}
              displayMode={displayMode}
              producerId={producerId}
              {...formValues}
            />
          )}
        {activeStep === 2 && report_type.value === OPTIMIZATION && (
          <Optimization
            templateReportId={templateReport?.reportId}
            {...formProps}
          />
        )}
        {activeStep === 3 && (
          <AdvancedConfig
            isProd={isProd(environment)}
            categories={formValues.competitive_set_folders}
            {...formProps}
          />
        )}

        {activeStep === 4 && (
          <Confirmation
            isProd={isProd(environment)}
            report_type={report_type}
            {...formProps}
          />
        )}

        <div className={styles.buttonContainer}>
          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              viewerRoles.viewerRoleIsSuperadmin &&
              activeStep === 2 &&
              (report_type.value === MARKET_SURVEY ||
                report_type.value === PRODUCT) &&
              competitive_set_rich.length > 0 && (
                <MaterialButton
                  onClick={() => setOpenClassDB(!openClassDB)}
                  variant="outlined"
                  soft
                  teal
                >
                  View Class DB
                </MaterialButton>
              )
            }
          />

          {activeStep > 0 && (
            <MaterialButton
              onClick={() => handleBackStep()}
              variant="outlined"
              soft
            >
              Back
            </MaterialButton>
          )}
          {activeStep < STEPS.length - 1 && (
            <MaterialButton
              onClick={() => handleNextStep()}
              variant="outlined"
              soft
              teal
              disabled={
                invalid ||
                invalidFields(
                  activeStep,
                  report_type.value,
                  competitive_set_rich,
                  competitive_set_folders,
                  formValues,
                )
              }
            >
              Next
            </MaterialButton>
          )}
          {activeStep === STEPS.length - 1 && (
            <MaterialButton
              variant="outlined"
              disabled={pristine || invalid || submitting}
              onClick={() => {
                event({
                  category: CAT_REQUEST_REPORT,
                  action: REQUEST_REPORT_SUBMIT,
                });
                setSubmitted(true);
                handleSubmit();
              }}
              soft
              teal
            >
              {t('general.confirm')}
            </MaterialButton>
          )}
        </div>
      </Paper>

      {openClassDB &&
        activeStep === 2 &&
        (report_type.value === MARKET_SURVEY ||
          report_type.value === PRODUCT) && (
          <Paper className={styles.container}>
            <ClassDBTable
              productIds={competitive_set_rich.map((product) => product.id)}
              categories={competitive_set_rich
                .filter((product) => !!product.category)
                .map((product) => product.category)}
            />
          </Paper>
        )}
    </Fragment>
  );
};

export default React.memo(RequestReport);
